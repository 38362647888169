import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DataCard from "../components/DataCard";

const groupByAttributes = (items) => {
  const grouped = items.reduce((acc, item) => {
    const key = `${item.title}-${item.category}-${item.wts || ""}-${
      item.size || ""
    }`;
    if (!acc[key]) {
      acc[key] = { ...item, quantity: 1 };
    } else {
      acc[key].quantity += 1;
    }

    return acc;
  }, {});

  return Object.values(grouped);
};

const Home = ({ setUserName }) => {
  const [fetchError, setFetchError] = useState(null);
  const [stockDatas, setStockDatas] = useState([]);
  const [vetementsDatas, setVetementsDatas] = useState([]);
  const [objetsDatas, setObjetsDatas] = useState([]);
  const [billeterieDatas, setBilleterieDatas] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let ownerId = params.get("id");

    if (!ownerId) {
      const rawQuery = location.search.slice(1);
      if (rawQuery) {
        ownerId = rawQuery;
      }
    }

    if (ownerId && stockDatas.length === 0) {
      const API = params.get("id")
        ? `https://api.firststock.fr/public/id/${ownerId}`
        : `https://api.firststock.fr/public/${ownerId}`;

      fetch(API, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.username) {
            setUserName(data.username);

            const loader = document.querySelector(".loader");
            const footer = document.querySelector(".footer");
            const searchbar = document.querySelector(".search-bar");

            loader.style.display = "none";
            footer.style.display = "flex";
            searchbar.style.display = "block";
          }

          if (Array.isArray(data.items)) {
            const stock = data.items
              .filter((item) => item.category === "SNEAKERS")
              .sort((a, b) => {
                const titleComparison =
                  a.title && b.title ? a.title.localeCompare(b.title) : 0;

                if (titleComparison !== 0) {
                  return titleComparison;
                }
                const getSizeNumber = (size) => {
                  const match = size.match(/^(\d{1,2})/);
                  return match ? parseInt(match[1], 10) : 0;
                };
                const sizeComparison =
                  getSizeNumber(a.size) - getSizeNumber(b.size);
                return sizeComparison;
              });
            const vetements = data.items
              .filter((item) => item.category === "VETEMENTS")
              .sort((a, b) =>
                a.title && b.title ? a.title.localeCompare(b.title) : 0
              );
            const objets = data.items
              .filter((item) => item.category === "OBJETS")
              .sort((a, b) =>
                a.title && b.title ? a.title.localeCompare(b.title) : 0
              );
            const billeterieValide = data.items
              .filter((item) => item.category === "BILLETERIE")
              .filter((item) => !isNaN(new Date(item.dateevent).getTime()))
              .sort((a, b) => new Date(a.dateevent) - new Date(b.dateevent));

            const billeterieInvalide = data.items
              .filter((item) => item.category === "BILLETERIE")
              .filter((item) => isNaN(new Date(item.dateevent).getTime()));
            const billeterie = [...billeterieValide, ...billeterieInvalide];

            setStockDatas(groupByAttributes(stock));
            setVetementsDatas(groupByAttributes(vetements));
            setObjetsDatas(groupByAttributes(objets));
            setBilleterieDatas(groupByAttributes(billeterie));
            setFetchError(null);
          } else {
            setFetchError("Not found");
          }

          if (data.result === "matched") {
            console.log("ID correspondant trouvé !");
          }
        })
        .catch((error) => {
          console.error("Erreur lors de l'envoi des données:", error);
          setFetchError(
            "Une erreur est survenue lors de la récupération des données."
          );
        });
    }
  }, [location.search, stockDatas]);

  const filterData = (data) => {
    return data.filter((item) => {
      const query = searchQuery.toLowerCase();
      return (
        item.title?.toLowerCase().includes(query) ||
        item.size?.toLowerCase().includes(query) ||
        item.category?.toLowerCase().includes(query) ||
        item.sku?.toLowerCase().includes(query)
      );
    });
  };

  return (
    <div className="page home">
      <div className="loader"></div>
      {fetchError && <p>{fetchError}</p>}

      {/* Barre de recherche */}
      <input
        type="text"
        placeholder="🔍 Search by title, size, sku or category"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar"
      />

      <div className="datas">
        {/* Display SNEAKERS items */}
        {filterData(stockDatas).length > 0 && (
          <div>
            <h2>Sneakers:</h2>
            <div className="data-grid">
              {filterData(stockDatas).map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}

        {/* Display VETEMENTS items */}
        {filterData(vetementsDatas).length > 0 && (
          <div>
            <h2>Clothes:</h2>
            <div className="data-grid">
              {filterData(vetementsDatas).map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}

        {/* Display OBJETS items */}
        {filterData(objetsDatas).length > 0 && (
          <div>
            <h2>Objects:</h2>
            <div className="data-grid">
              {filterData(objetsDatas).map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}

        {/* Display BILLETERIE items */}
        {filterData(billeterieDatas).length > 0 && (
          <div>
            <h2>Tickets:</h2>
            <div className="data-grid">
              {filterData(billeterieDatas).map((data) => (
                <DataCard key={data.id} data={data} />
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="footer">
        This stock is managed on the app FirstStock <br />{" "}
        <div>
          <a href="https://whop.com/first-stock/">Waitlist</a> |{" "}
          <a href="https://discord.gg/NHr6pSwrae">Discord</a>
          <br />
        </div>
        FIRSTSTOCK © 2024
      </div>
    </div>
  );
};

export default Home;
